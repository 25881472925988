import React, { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import "./JoinForm.scss";
import "./JsonEditor-react.d.ts";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import Ajv from "ajv";
import "brace/mode/json";
import "brace/theme/monokai";

import arrayMutators from "final-form-arrays";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { SESSIONSTORAGE_INITIAL_FORM } from "../App";
import { ConnectDataDto } from "../model/ConnectDataDto";
import { sendJWTCreate } from "../utils";
import qaChatProfiles from "../qaProfiles";

const ajv = new Ajv({ allErrors: true, verbose: true });
const required = (value: any) =>
  value !== undefined && value !== null && value !== ""
    ? undefined
    : "Required";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface Language {
  key: string;
  label: string;
}
interface Props {
  initialData: any;
  environmentList: string[];
  languageList: Language[];
  pluginList: string[];
  onReceiveJitsiJoinData: (data: ConnectDataDto, formResult: any) => void;
}

const JoinForm: React.FC<Props> = ({
  initialData,
  environmentList,
  languageList,
  pluginList,
  onReceiveJitsiJoinData
}) => {
  // state for checking which qa profile has been choosen
  const [qaProfile, setQaProfile] = useState("");
  const [intitialValues, setIntitialValues] = useState(initialData);

  useEffect(() => {
    if (qaProfile === "NONE" || qaProfile === "") {
      setIntitialValues(initialData);
    } else {
      const {
        displayName,
        isModerator,
        plugins,
        roomToken,
        serverEnvironment,
        userId,
        forceE2EE,
        managedKeyMode,
        advancedOptions,
        configOverwrites,
        avatar,
        lang
      } = qaChatProfiles.find(
        (profile: any) => profile?.name === qaProfile
      );

      const newInitialValues: any = {
        displayName,
        isModerator,
        plugins,
        roomToken,
        serverEnvironment,
        userId,
        lang,
        forceE2EE,
        managedKeyMode,
        advancedOptions,
        configOverwrites
      };

      if (avatar) {
        newInitialValues.avatar = avatar;
      }
      setIntitialValues(newInitialValues);
    }
  }, [initialData, qaProfile]);

  const onSubmit = useCallback(async (values: any) => {
    const data = await sendJWTCreate(values);

    localStorage.setItem(SESSIONSTORAGE_INITIAL_FORM, JSON.stringify(values));
    onReceiveJitsiJoinData(data, values);

    return data;
  }, [onReceiveJitsiJoinData])

  const validate = (values: any) => {
    const errors:any = {};
    if(values.advancedOptions) {
      const jsonConfig = values.configOverwrites;
      if(jsonConfig === null) {
        errors["configOverwrites"] = "not valid";

      }

    }

    return errors;
  };

  return (
    <div className={`join-form`}>
      <Form
        onSubmit={onSubmit}
        initialValues={intitialValues}
        mutators={{
          ...arrayMutators,
        }}
        validate={validate}
        render={({ handleSubmit, form }) => (
          <form className="form" onSubmit={handleSubmit}>
            <Stack>
              <Item>
                <Typography variant="h5" gutterBottom>
                  QA Profiles
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="env-selection-label">
                    QA Profile for Chat test *
                  </InputLabel>
                  <Select
                    value={qaProfile}
                    labelId="qa-profile-selection-label"
                    label="QA Profile for Chat test *"
                    onChange={(e) => setQaProfile(e.target.value)}
                  >
                    {qaChatProfiles.map((profile: any) => (
                      <MenuItem key={profile?.name} value={profile?.name}>{profile?.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Item>
            </Stack>
            <Stack>
              <Item>
                <Typography variant="h5" gutterBottom>
                  Environment options
                </Typography>
                Pattern of the options:  "conference client (environment) - jitsi server"
                <Field name={`serverEnvironment`} validate={required}>
                  {({ input, meta, form }) => (
                    <FormControl
                      fullWidth
                      error={!!meta.touched && !!meta.error}
                    >
                      <InputLabel id="env-selection-label">
                        Environment *
                      </InputLabel>
                      <Select
                        labelId="env-selection-label"
                        label="Environment *"
                        {...input}
                      >
                        {environmentList.map((env) => (
                          <MenuItem key={env} value={env}>
                            {env}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Item>
              <Item>
                <Typography variant="h5" gutterBottom>
                  User data
                </Typography>

                <Stack>
                  <Field name={`userId`} validate={required}>
                    {({ input, meta }) => (
                      <TextField
                        error={!!meta.touched && !!meta.error}
                        style={{ paddingBottom: 20 }}
                        label="User ID *"
                        variant="outlined"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`displayName`} validate={required}>
                    {({ input, meta }) => (
                      <TextField
                        error={!!meta.touched && !!meta.error}
                        style={{ paddingBottom: 20 }}
                        label="Username *"
                        variant="outlined"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name={`avatar`}>
                    {({ input, meta }) => (
                      <TextField
                        error={!!meta.touched && !!meta.error}
                        style={{ paddingBottom: 20 }}
                        label="Url to avatar"
                        variant="outlined"
                        {...input}
                      />
                    )}
                  </Field>
                  <Field name="isModerator" validate={required}>
                    {({ input, meta }) => (
                      <>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                          color={
                            !!meta.touched && !!meta.error ? "red" : undefined
                          }
                        >
                          Role
                        </Typography>
                        <div>
                          <ToggleButtonGroup
                            color="primary"
                            value={input.value}
                            exclusive
                            onChange={(ev, value) => input.onChange(value)}
                            aria-label="Platform"
                          >
                            <ToggleButton value={true}>Moderator</ToggleButton>
                            <ToggleButton value={false}>Visitor</ToggleButton>
                          </ToggleButtonGroup>
                        </div>
                      </>
                    )}
                  </Field>
                </Stack>
              </Item>
              <Item>
                <Typography variant="h5" gutterBottom>
                  Conference data
                </Typography>

                <Stack>
                  <Field name={`roomToken`} validate={required}>
                    {({ input, meta }) => (
                      <TextField
                        error={!!meta.touched && !!meta.error}
                        style={{ paddingBottom: 20 }}
                        label="Room ID *"
                        variant="outlined"
                        {...input}
                      />
                    )}
                  </Field>

                  <Typography variant="overline" display="block" gutterBottom>
                    Plugins
                  </Typography>
                  <FieldArray name="plugins">
                    {({ fields }) => (
                      <Stack>
                        {fields.map((name, index) => (
                          <div className="plugin-container" key={name}>
                            <Stack>
                              <Field name={`${name}.type`} validate={required}>
                                {({ input, meta }) => (
                                  <FormControl
                                    fullWidth
                                    error={!!meta.touched && !!meta.error}
                                  >
                                    <InputLabel
                                      id={`env-plugin-type-label-${index}`}
                                    >
                                      Type *
                                    </InputLabel>
                                    <Select
                                      style={{ marginBottom: 20 }}
                                      labelId={`env-plugin-type-label-${index}`}
                                      label="Type *"
                                      {...input}
                                    >
                                      <MenuItem value={"manifest"}>
                                        By manifest url
                                      </MenuItem>
                                      <MenuItem value={"plugin-id"}>
                                        By plugin id
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                )}
                              </Field>

                              <Field name={`${name}.type`}>
                                {({ input: typeInput }) =>
                                  typeInput?.value === "manifest" ? (
                                    <Field
                                      name={`${name}.manifestUrl`}
                                      validate={required}
                                    >
                                      {({ input, meta }) => (
                                        <TextField
                                          error={!!meta.touched && !!meta.error}
                                          style={{ paddingBottom: 20 }}
                                          label="Url of manifest *
                                      "
                                          variant="outlined"
                                          {...input}
                                        />
                                      )}
                                    </Field>
                                  ) : (
                                    <Field
                                      name={`${name}.pluginId`}
                                      validate={required}
                                    >
                                      {({ input, meta }) => (
                                        <FormControl
                                          fullWidth
                                          error={!!meta.touched && !!meta.error}
                                        >
                                          <InputLabel
                                            id={`env-plugin-type-label-${index}`}
                                          >
                                            PluginID *
                                          </InputLabel>
                                          <Select
                                            style={{ marginBottom: 20 }}
                                            labelId={`env-plugin-type-label-${index}`}
                                            label="PluginID *"
                                            {...input}
                                          >
                                            {pluginList.map((plugin) => (
                                              <MenuItem
                                                key={plugin}
                                                value={plugin}
                                              >
                                                {plugin}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      )}
                                    </Field>
                                  )
                                }
                              </Field>
                            </Stack>
                            <div>
                              <Button
                                variant="outlined"
                                type="button"
                                aria-label="add"
                                color="primary"
                                onClick={() => fields.remove(index)}
                                startIcon={<Remove />}
                              >
                                Remove plugin
                              </Button>
                            </div>
                          </div>
                        ))}

                        <div>
                          <Button
                            variant="outlined"
                            type="button"
                            aria-label="add"
                            color="primary"
                            onClick={() => fields.push({ type: "manifest" })}
                            startIcon={<Add />}
                          >
                            Add plugin
                          </Button>
                        </div>
                      </Stack>
                    )}
                  </FieldArray>
                </Stack>
              </Item>
            </Stack>

            <Stack>
              <Item>
                <Typography variant="h5" gutterBottom>
                  Conference Options
                </Typography>
                <FormControl fullWidth>
                  <FormGroup>
                    <Field name={`forceE2EE`}>
                      {({ input, meta }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={input.value}
                              onChange={(_e, checked) =>
                                input.onChange(checked)
                              }
                            />
                          }
                          label="Activate end-to-end encyrption"
                        />
                      )}
                    </Field>
                    <Field name={`managedKeyMode`}>
                      {({ input, meta }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={input.value}
                              onChange={(_e, checked) =>
                                input.onChange(checked)
                              }
                            />
                          }
                          label="Use managed keymode for e2ee"
                        />
                      )}
                    </Field>
                    
                    <Field name={`lang`} validate={required}>
                      {({ input, meta }) => (
                        <FormControl
                          className="language-picker"
                          fullWidth
                          error={!!meta.touched && !!meta.error}
                        >
                          <InputLabel id="lang-selection-label">
                            Language
                          </InputLabel>
                          <Select
                            labelId="lang-selection-label"
                            label="Language"
                            {...input}
                          >
                            {languageList.map((lang) => (
                              <MenuItem key={lang.key} value={lang.key}>
                                {lang.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </Field>
                  </FormGroup>
                </FormControl>
              </Item>
            </Stack>

            <Field name={`advancedOptions`}>
              {({ input: advancedOptionsInput }) => (
                <Stack>
                  <Item>
                    <div className="advancedOptionsContainer">
                      <Switch
                        checked={advancedOptionsInput.value}
                        onChange={(_e, checked) =>
                          advancedOptionsInput.onChange(checked)
                        }
                      />

                      <Typography variant="h5" gutterBottom>
                        Advanced Options
                      </Typography>
                    </div>
                    <Field name={`configOverwrites`}>
                      {({ input }) =>
                        advancedOptionsInput.value && (
                          <div className="json-input-container">
                            <Editor
                              ace={ace}
                              ajv={ajv}
                              theme="ace/theme/monokai"
                              mode="code"
                              value={input.value}
                              // onChangeText={(value: any) => console.log("onChangeText", value)}
                              onValidationError={(err:any) => {
                                if(err.length > 0) {
                                  console.log("onValidationError", err)
                                  input.onChange(null)
                                }

                              }}
                              onChange={(value: any) => {
                                input.onChange(value)
                              }
                              }
                            />
                            <div className="config-hint">
                              You can have a look into possible configurations for the newest version here <a  rel="noreferrer"  href="https://github.com/jitsi/jitsi-meet/blob/master/config.js" target="_blank">config.js on github</a>
                            </div>
                          </div>
                        )
                      }
                    </Field>
                  </Item>
                </Stack>
              )}
            </Field>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 20,
              }}
            >
              <Button size="large" variant="contained" type="submit">
                Join conference
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};
export default JoinForm;
