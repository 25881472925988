const plugins = [
    {
        type: "plugin-id",
        pluginId: "chat (TEST)",
    },
    {
        type: "plugin-id",
        pluginId: "System Check (TEST)",
    },
    {
        type: "plugin-id",
        pluginId: "dev-whiteboard",
    }
]

const commonProps = {
    forceE2EE: true,
    managedKeyMode: false,
    advancedOptions: false,
    configOverwrites: {},
    roomToken: "QA_Chat_Test",
    lang: "en",
    serverEnvironment: "TEST: conference-container-dev.elvi.world - jitsi2",
    plugins
}

const qaChatProfiles: any = [
    {
      name: "Codrin Chat Moderator",
      avatar:
        "https://w7.pngwing.com/pngs/142/1021/png-transparent-cat-computer-icons-user-profile-avatar-profile-mammal-animals-cat-like-mammal-thumbnail.png",
      displayName: "Codrin Paduraru",
      isModerator: true,
      userId: "cpaduraru",
      ...commonProps

    },
    {
      name: "Codrin Paduraru Visitor",
      displayName: "Codrin Paduraru Visitor",
      isModerator: false,
      userId: "cpaduraruVisit",
      ...commonProps
    },
    {
      name: "Kathi Chat Moderator",
      avatar:
        "https://image.shutterstock.com/image-vector/face-brown-dog-low-poly-260nw-2133211827.jpg",
      displayName: "Katharina Siekmann",
      isModerator: true,
      userId: "ksiekmann",
      ...commonProps
    },
    {
      name: "Kathi Chat Visitor",
      displayName: "Katharina Siekmann Visitor",
      isModerator: false,
      userId: "ksiekmannVisit",
      ...commonProps
    },
    {
      name: "Marina Chat Moderator",
      avatar:
        "https://images.clipartlogo.com/files/istock/previews/1030/103070367-animal-emotion-avatar-vector-icon.jpg",
      displayName: "Marina Dan",
      isModerator: true,
      userId: "mdan",
      ...commonProps
    },
    {
      name: "Marina Chat Visitor",
      displayName: "Marina Dan Visitor",
      isModerator: false,
      userId: "mdanVisitor",
      ...commonProps
    },
    {
      name: "NONE",
    },
  ];

export default qaChatProfiles;